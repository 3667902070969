import * as React from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Typography
} from '@mui/material';
import { HomeNavBarProps } from '../../helpers/interfaces';

const HomeNavBar: React.FC<HomeNavBarProps> = (
  props: HomeNavBarProps
): JSX.Element => {
  const tabs = ['Home', 'Patients', 'Providers'];
  return (
    <AppBar
      position="static"
      sx={{
        background: 'transparent',
        flexGrow: 1,
        mb: 2,
        maxWidth: {
          xs: '90%',
          sm: '50%',
          md: '40%',
          lg: '30%'
        },
        ml: 'auto',
        mr: 'auto'
      }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <>
          {tabs.map((tab) => {
            return (
              <Button
                key={tab}
                color="inherit"
                style={{
                  fontSize: 'large',
                  backgroundColor:
                    props.view === tab
                      ? '#ADD8E6'
                      : 'transparent'
                }}
                onClick={() => {
                  props.setView(tab);
                }}>
                <Typography
                  fontWeight="bold"
                  sx={{ color: '#000000' }}>
                  {tab}
                </Typography>
              </Button>
            );
          })}
        </>
      </Toolbar>
    </AppBar>
  );
};

export default HomeNavBar;
